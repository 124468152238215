import axios from 'axios';
import {urlBackend} from '../main/Util'
axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://localhost:3000';
axios.defaults.headers.post['Cache-control'] = 'no-cache';



export const api = axios.create({
    baseURL: urlBackend,
    withCredentials: true   
});


class ApiService{

    constructor(apiUrl){
        this.token = null;
        this.apiUrl = apiUrl;
    } 

    static registerSession(token){      
        if(token){
            this.token = token;
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`        
        }
    }

    post(url, objeto){
        const requestUrl = `${this.apiUrl}${url}`
        return api.post(requestUrl, objeto);
    }

    get(url, params){
        const requestUrl = `${this.apiUrl}${url}`
        return api.get(requestUrl,{params});
    }

    delete(url, objeto){
        const requestUrl = `${this.apiUrl}${url}`
        return api.delete(requestUrl, objeto);
    }

    put(url, objeto){
        const requestUrl = `${this.apiUrl}${url}`
        return api.put(requestUrl, objeto);
    }

    removeAcento(text) {       
        text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
        text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
        text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
        text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
        text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
        text = text.replace(new RegExp('[Ç]','gi'), 'c');
        
        return text;                 
    }

}

export default ApiService;
