import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCodEmpUser } from "../api/services/AuthService";
import { setSessionExpired } from "../api/services/AuthService"


const ProtectedRoute = (props) => {
    let navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    setSessionExpired();
    const checkUserToken = () => {
        if (!getCodEmpUser) {
            console.log("1")
            setIsLoggedIn(false);
            navigate('/')
        }
        setIsLoggedIn(true);
    }
    useEffect(() => {
        checkUserToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);
    
    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;
