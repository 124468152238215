import ApiService from "../api";

class FaturaService extends ApiService{

    constructor(){
        super('/portal/fatura')
    }

    listar(filtro){
        return this.post('/listarFaturas', filtro)
    }

    detalhar(filtro){
        return this.post('/detalharFatura', filtro)
    }


}

export default FaturaService;