import axios from 'axios';
import {urlBackend} from '../../main/Util'

//const baseURL = process.env.REACT_APP_API_URL;
//axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
axios.defaults.headers.post['Content-Type'] ='multipart/form-data';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Cache-control'] = 'no-cache';



export const axiosApiUpload = axios.create({
    baseURL:urlBackend,
    withCredentials: true   
});

class UploadFileService{

    constructor(){
        this.apiUrl = '/portal/solicitacao';
    }

    static registerSession(token){
        if(token){
            axiosApiUpload.defaults.headers.common['Authorization'] = `Bearer ${token}`        
        }
    }

    post(url, objeto){
        const requestUrl = `${this.apiUrl}${url}`
        return axiosApiUpload.post(requestUrl, objeto);
    }

    get(url, objeto){
        const requestUrl = `${this.apiUrl}${url}`
        return axiosApiUpload.get(requestUrl, objeto);
    }

    delete(url, objeto){
        const requestUrl = `${this.apiUrl}${url}`
        return axiosApiUpload.delete(requestUrl, objeto);
    }

    put(url, objeto){
        const requestUrl = `${this.apiUrl}${url}`
        return axiosApiUpload.put(requestUrl, objeto);
    }

    salvar(data){
        return this.post('/salvarArquivo', data)
    }

    findLastId(){
        return this.post('/getLastId');
    }
}

export default UploadFileService;