import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../api/api';
import { axiosApiUpload } from '../api/services/UploadFileService';
import { apiFile } from '../api/services/FileService'
import { setSessionExpired } from "../api/services/AuthService"

export const SESSION_EXPIRED = '_session_expired';

export function useAxiosNavigation() {
    let navigate = useNavigate();
    useEffect(() => {
        const intercetpor = api.interceptors.response.use(
            (response) => response,
            (error) => {
                return handleError(error, navigate)
            }
        );
        return () => {
            api.interceptors.response.eject(intercetpor);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const intercetpor = axiosApiUpload.interceptors.response.use(
            (response) => response,
            (error) => {
                return handleError(error, navigate)
            }
        );
        return () => {
            axiosApiUpload.interceptors.response.eject(intercetpor);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const intercetpor = apiFile.interceptors.response.use(
            (response) => response,
            (error) => {
                return handleError(error, navigate)
            }
        );
        return () => {
            apiFile.interceptors.response.eject(intercetpor);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

const handleError = (error, navigate) => {
    switch (error?.response?.status) {
        case 401:
            sessionExpired(navigate);
            break;
        case 403:
            sessionExpired(navigate);
            break;
        default:
    }
    return Promise.reject(error);
}

const sessionExpired = (navigate) => {
    setSessionExpired();
    navigate('/login')
}

export default function AxiosNavigation() {
    useAxiosNavigation();
    return <></>;
}