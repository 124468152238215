import { getItem, removeItem, addItemFromObject, clearStorage, getItemStorage } from "./LocalStorageService";

import jwt from 'jsonwebtoken'

import api from '../api.js'

import DownloadFileService from "./UploadFileService";
import FileService from "./FileService";
import ReportFaturaService from "./ReportFaturaService";

export const KEY_USER = "_logged_user";
export const SESSION_EXPIRED = '_session_expired';
export const DEFAULT_PASSWORD = '_default_password';

const setSessionActive = () => {
    addItemFromObject(SESSION_EXPIRED, false);
}

const setSessionExpired = () => {
    addItemFromObject(SESSION_EXPIRED, true);
}

const setPasswordDefault = () => {
    addItemFromObject(DEFAULT_PASSWORD, true);
}

const setPasswordFull = () => {
    addItemFromObject(DEFAULT_PASSWORD, false);
}

const isSessionExpired = () => {
    return getItem(SESSION_EXPIRED);
}

const isPasswordDefault = () => {
    return getItemStorage(DEFAULT_PASSWORD);
}



const clearStoragePortal = () => {
    clearStorage();
}

const isUserAuthenticate = () =>{
    const user = getItem(KEY_USER);
    if(!user){
        return user;
    }
    const decodedToken = jwt.decode(user.token)
    const expiration = decodedToken.exp
    const isTokenInvalido = Date.now() >= (expiration * 1000)
    const result = !isTokenInvalido ? user : null;

    if(result){
       api.registerSession(user.token);
       DownloadFileService.registerSession(user.token);
       FileService.registerSession(user.token);
       ReportFaturaService.registerSession(user.token);
    }

    return result;
}

const getCodEmpUser = () => {
    const jsonToken = isUserAuthenticate();
    if(jsonToken){
        const objToken = jwt.decode(jsonToken.token);    
        return objToken.codEmpresa;
    }else{
        return null;
    }   
}

const isAdmin = () => {
    const jsonToken = isUserAuthenticate();
    if(jsonToken){
        const objToken = jwt.decode(jsonToken.token);    
        return objToken.admin;
    }else{
        return null;
    }  
}

const getUsername = () => {
    const jsonToken = isUserAuthenticate();
    if(jsonToken){
        const objToken = jwt.decode(jsonToken.token);    
        return objToken.username;
    }else{
        return null;
    }   
}

const removeUserAuthenticate = () => {
    removeItem(KEY_USER);
}

const verifyFirstLogin = (json) => {
    const decodedToken = jwt.decode(json.token);
    const reset = decodedToken.reset;
    addUserAuthenticate(json);

    return reset;
}

const addUserAuthenticate = (json) => {
    const user = JSON.stringify(json)
    addItemFromObject(KEY_USER, user);
    api.registerSession(user.token);
    DownloadFileService.registerSession(user.token);
}

export {isPasswordDefault, setPasswordFull, setPasswordDefault, verifyFirstLogin, isUserAuthenticate, removeUserAuthenticate, addUserAuthenticate, getCodEmpUser, getUsername, setSessionExpired, isAdmin, isSessionExpired, setSessionActive, clearStoragePortal};