import React from "react";

//import CadastrarReembolso from "../views/reembolso/CadastrarReembolso";
//import DetalharReembolso from "../views/reembolso/DetalharReembolso";
//import ListarReembolso from "../views/reembolso/ListarReembolso";
import ListarFatura from "../views/fatura/ListarFatura";
import DetalharFatura from "../views/fatura/DetalharFatura";
import CadastroConfiguracoes from "../views/sistema/CadastroConfiguracoes";
import Login from "../login/form/Login";
import ResetSenha from "../views/sistema/ResetSenha";
import { AnimatePresence } from "framer-motion";
import AxiosNavigation from "./AxiosNavigation";
import ProtectedRoute from "./ProtectedRoute";
import DashBoard from "../views/dashboard/Dashboard";
import RecuperarSenha from "../login/form/RecuperarSenha";


import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

const Rotas = () => {
    return (
        <AnimatePresence>
            <Router>
                <AxiosNavigation />
                <Routes>
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/recuperarSenha" element={<RecuperarSenha />} />
                    <Route exact path="/" element={<Login />} />
                    <Route
                        exact
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <DashBoard />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        exact
                        path="/fatura"
                        element={
                            <ProtectedRoute>
                                <ListarFatura />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        exact
                        path="/detalharFatura"
                        element={
                            <ProtectedRoute>
                                <DetalharFatura />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        exact
                        path="/redefinirSenha"
                        element={
                            <ProtectedRoute>
                                <ResetSenha />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        exact
                        path="/sistema"
                        element={
                            <ProtectedRoute>
                                <CadastroConfiguracoes />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </Router>
        </AnimatePresence>
    );
};

//Rotas não utilizadas
//<Route exact path="/reembolso" element={isUserAuthenticate() ? <ListarReembolso /> : <Login />} />
//<Route exact path="/detalharReembolso" element={isUserAuthenticate() ? <DetalharReembolso /> : <Login />} />
//<Route exact path="/cadastrarReembolso" element={isUserAuthenticate() ? <CadastrarReembolso /> : <Login />} />
//<Route exact path="/dashboard" element={isUserAuthenticate() ? <ListarReembolso /> : <Login />} />
//<Route exact path="/sistema" element={isUserAuthenticate() ? <CadastroConfiguracoes /> : <Login />} />

export default Rotas;