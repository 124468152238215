import React from 'react';
import Rotas from './components/main/Routes';
import styled from "styled-components";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/theme/Theme'


import GlobalStyle from './GlobalStyle';

const MainSection = styled.section`
    width:100%;
    height:100%;
    bottom:0;
    position:relative;   
`;
function App() {

    return (
        <MainSection className="main-section">
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Rotas />
            </ThemeProvider>
        </MainSection>
    );
}

export default App;
