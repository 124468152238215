import { createGlobalStyle } from "styled-components";


export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;

  margin: 0;
  padding: 0;
  text-decoration: none;
}

.MuiDataGrid-columnsContainer{
    background-color:#4682B4;
    color:white;
    font-weight:bold;
    font-size:14px;
}

.MuiDataGrid-columnHeaderTitle{
}
.p-tabview-panels{
  padding:0 !important;
}

body {
  
  margin: 0;
    sans-serif;
  -webkit-font-smoothing: antialiased;
  *-moz-osx-font-smoothing: grayscale;
  height:100%;
  display: flex;
	flex-direction: column;
  text-rendering:optimizeLegibility;
}

code {
    monospace;
}
`;



export default GlobalStyle;
