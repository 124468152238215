import { createTheme, responsiveFontSizes } from "@material-ui/core";


let theme = createTheme({
    palette: {
      primary: {
        main: "#4682B4",
        contrastText: "white",
      },
    },
    typography: {
      fontFamily: [
        'Roboto',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
});

theme = responsiveFontSizes(theme)
export default theme