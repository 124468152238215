import axios from 'axios';
import {urlBackend} from '../../main/Util'

export const apiFile = axios.create({
    baseURL:urlBackend,
    withCredentials: true,
    responseType: 'blob',  
});


class FileService{

    constructor(){
        this.apiUrl = '/portal/solicitacao';
    }

    static registerSession(token){
        if(token){
            apiFile.defaults.headers.common['Authorization'] = `Bearer ${token}` 
            apiFile.defaults.headers.common['Cache-Control'] = `no-cache`        
        }
    }

    get(url, id){
        const requestUrl = `${this.apiUrl}${url}`
        return apiFile.get(requestUrl, { params: {id:id} });
    }

}

export default FileService;