import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment'
import styled from "styled-components";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';


const SpanTitle = styled.span`
    font-size:18px;
    font-weight:bold;
    color:#4682B4;
    
`
const SpanData = styled.span`
    margin-right:50px;
    font-size:18px;
    color:#4682B4;
    
    font-weight:bold;
    display:inline-block;
    width:300px;
`
const FooterGroup = styled.p`
    text-align:right;
    font-size:18px;
    color:#4682B4;
    
    font-weight:bold;
`
const HeaderTable = styled.div`
    text-align:center;
    color:#4682B4;
    
    font-size:24px;
`

const IconHeader = styled(DirectionsCarIcon)`
    vertical-align:text-bottom;
    margin-right:10px;
    width: 30px !important;
    height: 30px !important;
`

const HeaderTitulo = styled.span`
    margin-bottom:10px;
    
`


const TabelaCarros = (props) => {
    const [carros, setCarros] = useState([]);
    const [totalDiaria, setTotalDiaria] = useState(0.00);
    const [totalTotalizadorDiaria, setTotalTotalizadorDiaria] = useState(0.00);
    const [totalTaxa, setTotalTaxa] = useState(0.00);
    const [totalCartao, setTotalCartao] = useState(0.00);
    const [totalComissao, setTotalComissao] = useState(0.00);
    const [totalIncentivo, setTotalIncentivo] = useState(0.00);
    const [totalDesconto, setTotalDesconto] = useState(0.00);
    const [totalFee, setTotalFee] = useState(0.00);
    const [totalLiquido, setTotalLiquido] = useState(0.00);
    const [totalExtras, setTotalExtras] = useState(0.00);

    useEffect(() => {
        setCarros(props.fatura.carros);
        setTotalDiaria(formatNumberPosMount(props.fatura.totalDiariaCarro));
        setTotalTaxa(formatNumberPosMount(props.fatura.totalTaxaCarro));
        setTotalCartao(formatNumberPosMount(props.fatura.totalCartaoCarro));
        setTotalComissao(formatNumberPosMount(props.fatura.totalComissaoCarro));
        setTotalIncentivo(formatNumberPosMount(props.fatura.totalIncentivoCarro));
        setTotalDesconto(formatNumberPosMount(props.fatura.totalDescontoCarro));
        setTotalFee(formatNumberPosMount(props.fatura.totalFeeCarro));
        setTotalLiquido(formatNumberPosMount(props.fatura.totalLiquidoCarro));
        setTotalTotalizadorDiaria(formatNumberPosMount(props.fatura.totalTotalizadorDiariaCarro));
        setTotalExtras(formatNumberPosMount(props.fatura.totalExtrasCarro));
    }, [carros, totalTaxa, totalCartao, totalComissao, totalIncentivo, totalDesconto, totalFee, totalLiquido,
        props.fatura.carros, props.fatura.totalTarifaGeral, props.fatura.totalTaxa,
        props.fatura.totalCartao, props.fatura.totalComissao, props.fatura.totalIncentivo,
        props.fatura.totalDesconto, props.fatura.totalImposto, props.fatura.totalFee, props.fatura.totalTxacrt,
        props.fatura.totalTxDu, props.fatura.totalLiquido, props.fatura.TabelaHoteis, props.fatura.hoteis,
        props.fatura.totalDiariaHotel, props.fatura.totalTaxaHotel, props.fatura.totalCartaoHotel,
        props.fatura.totalComissaoHotel, props.fatura.totalIncentivoHotel, props.fatura.totalDescontoHotel,
        props.fatura.totalFeeHotel, props.fatura.totalLiquidoHotel, props.fatura.totalTotalizadorDiariaHotel,
        props.fatura.totalExtrasHotel, props.fatura.totalDiariaCarro, props.fatura.totalTaxaCarro,
        props.fatura.totalCartaoCarro, props.fatura.totalComissaoCarro, props.fatura.totalIncentivoCarro,
        props.fatura.totalDescontoCarro, props.fatura.totalFeeCarro, props.fatura.totalLiquidoCarro,
        props.fatura.totalTotalizadorDiariaCarro, props.fatura.totalExtrasCarro]);


    const formatNumberPosMount = (number) => {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const formatNumber = new Intl.NumberFormat('pt-BR', options)
        return number !== "undefined" ? formatNumber.format(number) : 0.00;
    }

    const header = (
        <HeaderTable>
            <IconHeader /><HeaderTitulo>LOCAÇÃO</HeaderTitulo>
        </HeaderTable>
    );

    let footerGroup =
        <ColumnGroup>
            <Row>
                <Column style={{ width: '400px', color: "white", backgroundColor: "#4682B4"}} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer="Total Geral:" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer={totalDiaria} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer={totalTotalizadorDiaria} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer={totalTaxa} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer={totalExtras} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer={totalCartao} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer={totalIncentivo} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer={totalComissao} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer={totalDesconto} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer={totalFee} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4682B4"}} footer={totalLiquido} />
            </Row>
        </ColumnGroup>;

    const reactFooter = (totalizadores) => {
        return (
            <React.Fragment>
                <td style={{ width: '400px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '150px' }}><FooterGroup>Total Locação:</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.diaria}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.totalDiaria}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.totalTaxa}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.extras}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.cartao}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.incentivo}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.comissao}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.desconto}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.fee}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.liquido}</FooterGroup></td>
            </React.Fragment>
        );
    }

    const footerTemplate = (data) => {
        let totalizadores = countCarroslTotal(data.grupoCarro);

        return reactFooter(totalizadores);
    }

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <SpanTitle>Locadora:&nbsp;</SpanTitle><SpanData>{data.localin}</SpanData>
                <SpanTitle>Emissão:&nbsp;</SpanTitle><SpanData>{data.fpagtoFormatado}</SpanData>
                <SpanTitle>Tipo:&nbsp;</SpanTitle><SpanData>{data.tipoFormatado}</SpanData>
            </React.Fragment>
        );
    }

    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    const formatNumber = new Intl.NumberFormat('pt-BR', options)

    const extrasBodyTemplate = (rowData) => {
        let value = rowData.valextcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const cartaoBodyTemplate = (rowData) => {
        let value = rowData.valcrtcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const comissaoBodyTemplate = (rowData) => {
        let value = rowData.valrpcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const incentivoBodyTemplate = (rowData) => {
        let value = rowData.valcmcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const descontoBodyTemplate = (rowData) => {
        let value = rowData.valdesc;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const liquidoBodyTemplate = (rowData) => {
        let value = rowData.valarec;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const feeBodyTemplate = (rowData) => {
        let value = rowData.txadcli;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const taxaBodyTemplate = (rowData) => {
        let value = rowData.taxa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const totalDiariasBodyTemplate = (rowData) => {
        let value = rowData.tarifa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const diariaBodyTemplate = (rowData) => {
        let value = rowData.tarifpro;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const paxBodyTemplate = (rowData) => {

        let value = rowData.pax;
        var passageiros = value.split("%");
        return (
            <div className="flex flex-wrap gap-2">
                {passageiros.map(pax => <><span>{pax}</span><br></br></>)}
            </div>
        );
    }

    const dataEmissaoBodyTemplate = (rowData) => {
        return moment(rowData.emissao).format('DD/MM/YYYY')
    }

    const checkinBodyTemplate = (rowData) => {
        return moment(rowData.datain).format('DD/MM/YYYY')
    }

    const checkoutBodyTemplate = (rowData) => {
        return moment(rowData.dataout).format('DD/MM/YYYY')
    }

    const countCarroslTotal = (grupo) => {
        let totalDiaria = 0;
        let totalTotalizadorDiaria = 0;
        let totalTaxa = 0;
        let totalExtras = 0;
        let totalCartao = 0;
        let totalIncentivo = 0;
        let totalComissao = 0;
        let totalDesconto = 0;
        let totalFee = 0;
        let totalLiquido = 0;

        if (carros) {
            for (let carro of carros) {
                if (grupo === undefined || carro.grupoCarro === grupo) {
                    totalDiaria += carro.tarifpro;
                    totalTotalizadorDiaria += carro.tarifa;
                    totalTaxa += carro.taxa;
                    totalCartao += carro.valcrtcl;
                    totalExtras += carro.valextcl;
                    totalIncentivo += carro.valcmcl;
                    totalComissao += carro.valrpcl;
                    totalDesconto += carro.valdesc;
                    totalFee += carro.txadcli;
                    totalLiquido += carro.valarec;
                }
            }
        }
        return {
            diaria: formatNumber.format(totalDiaria),
            totalDiaria: formatNumber.format(totalTotalizadorDiaria),
            totalTaxa: formatNumber.format(totalTaxa),
            cartao: formatNumber.format(totalCartao),
            extras: formatNumber.format(totalExtras),
            incentivo: formatNumber.format(totalIncentivo),
            comissao: formatNumber.format(totalComissao),
            desconto: formatNumber.format(totalDesconto),
            fee: formatNumber.format(totalFee),
            liquido: formatNumber.format(totalLiquido),
        };
    }

    return (
        <DataTable footerColumnGroup={footerGroup} header={header}
            size="small" rowGroupFooterTemplate={footerTemplate} stripedRows value={carros}
            responsiveLayout="scroll" rowGroupMode="subheader" groupRowsBy="grupoCarro"
            sortMode="single" sortOrder={1} rowGroupHeaderTemplate={headerTemplate} scrollable scrollHeight="500px" scrollDirection="both" >
            <Column bodyStyle={{ width: '400px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '400px'}} field={paxBodyTemplate} header="Nome (s)"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={dataEmissaoBodyTemplate} header="EMISSÃO"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field="numresv" header="Nº RESERVA"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={checkinBodyTemplate} header="CHECKIN"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={checkoutBodyTemplate} header="CHECKOUT"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field="quanpax" header="Nº PAX"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field="qtddias" header="Nº DIÁRIAS"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={diariaBodyTemplate} header="DIÁRIA R$"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={totalDiariasBodyTemplate} header="TOTAL DIÁRIAS"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={taxaBodyTemplate} header="TAXA"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={extrasBodyTemplate} header="EXTRAS"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={cartaoBodyTemplate} header="CARTÃO"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={incentivoBodyTemplate} header="INCENTIVO"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={comissaoBodyTemplate} header="COMISSÃO"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={descontoBodyTemplate} header="DESCONTO"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={feeBodyTemplate} header="FEE"></Column>
            <Column bodyStyle={{ width: '150px'}} headerStyle={{ backgroundColor: '#4682B4', color: 'white', width: '150px'}} field={liquidoBodyTemplate} header="LÍQUIDO"></Column>
        </DataTable>
    );
}

export default TabelaCarros;