import ApiService from "../api";

class DashboardService extends ApiService{
    constructor(){
        super('/portal/dashboard')
    }

    getTotalizadores(codemp){
        return this.post("/getCounters", codemp)
    }
}

export default DashboardService;