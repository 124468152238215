import ApiService from "../api";
import { getCodEmpUser } from './AuthService';

class SistemaService extends ApiService{

    constructor(){
        super('/portal/sistema')
    }

    listar(){
        return this.post('/listEmails', {codemp:getCodEmpUser()})
    }

    salvar(email){
        const saveEmail = {
            descricao : email,
            codemp:getCodEmpUser()
        }
        return this.post('/saveEmail', saveEmail);
    }

    alterar(email){
        return this.post('/updateEmail', email);
    }

    deletar(email){
        return this.post('/deleteEmail', email);
    }
}

export default SistemaService;