import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import { TextField } from '@material-ui/core';
import { verifyFirstLogin, isSessionExpired, setSessionActive, clearStoragePortal, setPasswordDefault, setPasswordFull } from '../../api/services/AuthService'
import Alert from '@mui/material/Alert';
import aviao from '../../../assets/images/aviao.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme/Theme';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import AirlinesIcon from '@mui/icons-material/Airlines';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import styled from "styled-components";

import LoginService from '../../api/services/LoginService';

const DivLogin = styled.div`
    background-image:url(${aviao});  
    position:fixed;
    z-index:0;
    width:100%;
    height:100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: right top;
`

const CardLogin = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;  
`

const Titulo = styled(Typography)`
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    position:relative;
    z-index:2; 
    color:white;
    padding-top:20vh; 
`

const CardHeaderLogin = styled(CardHeader)`
    background-color:#4682B4;
    color:white;
    font-height:bold;
    width:600px;
`

const AlertBadCredentials = styled(Alert)`
    margin-bottom:5px;
    display: ${({ isvisible }) => isvisible === 'true' ? 'flex !important' : 'none !important'}

`

const LinkMenu = styled(Link)`
    margin: 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    color: inherit;
    text-decoration:none;
`
export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [variant, setVariant] = useState("error");
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        const statusSessionExpired = () => {
            setVariant("warning");
            setMessage('Sessão Encerrada!');
            setError(true)
            clearStoragePortal();
        }
        if (error !== true && isSessionExpired() === true) {
            statusSessionExpired();
        }

    }, [variant, message, error]);

    const servico = new LoginService()

    const createSession = (jsonToken) => {
        return verifyFirstLogin(jsonToken);
    };

    return (
        <form onSubmit={(event) => {
            setOpen(true);
            setError(false)
            event.preventDefault();
            servico.autenticar({
                username: username,
                password: password
            }).then(response => {
                const jsonToken = response.data;
                servico.clearStorage();
                let reset = createSession(jsonToken);
                setSessionActive();
                if (reset) {
                    setPasswordFull();
                    navigate('/fatura');
                    navigate(0);
                } else {
                    setPasswordDefault();
                    navigate('/redefinirSenha');
                    navigate(0);
                }
            }).catch(erro => {
                setError(true);
                setVariant("error");
                switch (erro?.response?.status) {
                    case 401 || 403:
                        setMessage("Credenciais Inválidas")
                        break;
                    default:
                        setMessage("Problema ao tentar logar, entre em contato com o adm do sistema!")
                        break;
                }
            }).finally(() => {
                setOpen(false);
            })
        }}>
            <ThemeProvider theme={theme}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DivLogin>
                    <Titulo variant="h3" gutterBottom>
                        <AirlinesIcon sx={{ fontSize: 50 }}></AirlinesIcon> PORTAL DO AGENTE
                    </Titulo>
                    <CardLogin>
                        <Card variant="outlined">
                            <CardHeaderLogin title="LOGIN" />
                            <CardContent>
                                <TextField
                                    id="username"
                                    autoComplete="off"
                                    label="Usuário"
                                    type="text"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    placeholder="Usuário"
                                    hiddenLabel
                                    value={username}
                                    onChange={(event) => {
                                        setUsername(event.target.value);
                                    }}
                                />

                                <TextField
                                    id="password"
                                    autoComplete="off"
                                    required
                                    hiddenLabel
                                    label="Senha"
                                    name="senha"
                                    type="password"
                                    margin="normal"
                                    placeholder="Senha"
                                    variant="outlined"
                                    fullWidth
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                />
                                <AlertBadCredentials isvisible={error.toString()} variant="filled" severity={variant}>
                                    {message}
                                </AlertBadCredentials>

                                <Grid container spacing={2} style={{ marginTop: 5, marginBotton: '1em' }}>
                                    <Grid item xs={6}>
                                        <Button type="submit" variant="contained" color="primary">
                                            Logar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LinkMenu to='/recuperarSenha'>
                                            <ListItem>
                                                <ListItemText sx={{ color: "#4682B4", textAlign:"right" }} primary={"Esqueci minha senha"} color='primary' />
                                            </ListItem>
                                        </LinkMenu>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </CardLogin>
                </DivLogin>
            </ThemeProvider>
        </form>
    );
}