import ApiService from "../api";

class ResetService extends ApiService{

    constructor(){
        super('/portal/api')
    }

    resetar(credentials){
        const requestUrl = `/resetar`
        return this.post(requestUrl, credentials);
    }
}

export default ResetService;